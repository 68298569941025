import * as React from 'react'
import { TaskAPI } from '../Services/TaskAPI';
import { DetailsList, IconButton, SearchBox, SelectionMode, Spinner, SpinnerSize, TooltipHost } from 'office-ui-fabric-react';
import { CommonUtility } from '../../../CommonUtility/CommonUtility';
import { ATTACH_ASSET_COLUMNS } from '../Constants/TaskConstants';
import { calloutProps, IconConstants, tooltipId } from '../../../Constants/StylesConstants';
import AlertRedux from '../../Alert/AlertRedux';
import { TextField } from 'office-ui-fabric-react';
import { TaskUtility } from './../../Tasks/Utility/TaskUtility';
import { JobAPI } from '../../Jobs/Services/JobAPI';
import ConfirmAttachAssetRedux from './ConfirmAttachAssetRedux';

export default class AttachAsset extends React.Component<any, any> {

    private _taskApi: TaskAPI;

    private _jobApi = null;

    _taskUtility = null;

    public constructor(props: any) {
        super(props);
        let config = {
            headers: {}
        };
        this._jobApi = new JobAPI(window.API_URL, config);
        this._taskApi = new TaskAPI(window.API_URL);
        this._taskUtility = new TaskUtility();
    }

    public async componentDidMount() {

        this.props.loadAssetsForBlanklocationTask({
            apiObject: this._taskApi,
            SelectedTask: this.props.Task.SelectedTask,
        });

    }

    public changeSearchField = (value: string, fieldName: string): void => {
        this.props.changeAttachAssetSearchFieldValue({
            fieldName, value
        })
    }

    public render(): JSX.Element {

        let {
            AssetList,
            isLoadingAttachAssetTab,
            alertJSON,
            newAttachAssetDetails,
            SelectedTask,
            searchedAssetName,
            searchedAssetType,
            searchedAssetClientName,
            searchedAssetSiteName,
            hideAttachAssetConfirm
        } = this.props.Task;

        const UPDATED_ASSET_COLUMNS = [...ATTACH_ASSET_COLUMNS, {
            key: 'column0',
            name: 'Action',
            fieldName: 'Dummy',
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            onRender: (item: any) => {
                return (
                    <TooltipHost
                        content={item.parentId !== item.scheduleSiteId ? `Shift and Attach Asset` : `Attach Asset`}
                        id={tooltipId}
                        calloutProps={calloutProps}
                        styles={{
                            root:
                            {
                                textAlign: "center",
                                display: 'inline-block'
                            }
                        }}
                    >

                        <IconButton
                            iconProps={IconConstants.attachIcon}
                            styles={{
                                root: {
                                    color: "#4285f4"
                                }
                            }}
                            onClick={() => {

                                this.props.openCloseAttachAssetConfirmModal({
                                    selectedAsset: item,
                                    hideAttachAssetConfirm: false,
                                    isShiftAndAttach: item.parentId !== item.scheduleSiteId
                                });
                            }}
                        />
                    </TooltipHost>
                );
            }
        }];

        let filteredAssetList: any[] = this._taskUtility.filterAssets([...AssetList], searchedAssetName,
            searchedAssetType,
            searchedAssetClientName,
            searchedAssetSiteName);

        return (
            <React.Fragment>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <SearchBox
                                placeholder="Name"
                                underlined
                                value={searchedAssetName}
                                onChange={(event: any, newVal: string) => {
                                    this.changeSearchField(newVal, "name");
                                }}
                                onClear={() => {
                                    this.changeSearchField("", "name");
                                }}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <SearchBox
                                placeholder="Asset Type"
                                underlined
                                value={searchedAssetType}
                                onChange={(event: any, newVal: string) => {
                                    this.changeSearchField(newVal, "assetType");
                                }}
                                onClear={() => {
                                    this.changeSearchField("", "assetType");
                                }}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <SearchBox
                                placeholder="Client"
                                underlined
                                value={searchedAssetClientName}
                                onChange={(event: any, newVal: string) => {
                                    this.changeSearchField(newVal, "client");
                                }}
                                onClear={() => {
                                    this.changeSearchField("", "client");
                                }}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <SearchBox
                                placeholder="Site"
                                underlined
                                value={searchedAssetSiteName}
                                onChange={(event: any, newVal: string) => {
                                    this.changeSearchField(newVal, "site");
                                }}
                                onClear={() => {
                                    this.changeSearchField("", "site");
                                }}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                            <TextField
                                value={newAttachAssetDetails.value}
                                onChange={(ev, newVal) => {
                                    this.props.changeNewAttachAssetName({
                                        NewAssetName: newVal,
                                    })
                                }}
                                placeholder="New Asset Name"
                                errorMessage={newAttachAssetDetails.errorMessage}
                                required

                                iconProps={{
                                    iconName: !newAttachAssetDetails.error ?
                                        IconConstants.saveicon.iconName : IconConstants.holdIcon.iconName,
                                    style: {
                                        pointerEvents: "auto",
                                        cursor: newAttachAssetDetails.error ? "default" : "pointer"
                                    },
                                    onClick: () => {
                                        if (!newAttachAssetDetails.error) {
                                            this.props.saveNewAsset({
                                                jobApi: this._jobApi,
                                                apiObject: this._taskApi,
                                                SelectedTask: SelectedTask,
                                                locationPayload: {
                                                    assetTypeId: AssetList[0]?.assetTypeId,
                                                    companyId: AssetList[0]?.companyId,
                                                    parentId: AssetList[0]?.parentId,
                                                    objectType: "ASSET",
                                                    name: newAttachAssetDetails.value,
                                                    type: 1,
                                                    status: 1,
                                                    locationLevel: 1
                                                }
                                            })

                                        }
                                    }
                                }}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1"></div>
                    </div>
                    {isLoadingAttachAssetTab ?
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="login-spinner-div">
                                <Spinner
                                    label="Please wait..."
                                    size={SpinnerSize.large} />
                            </div>
                        </div>
                        :
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                            <DetailsList
                                items={filteredAssetList}
                                compact={true}
                                columns={CommonUtility.sortByColumn(UPDATED_ASSET_COLUMNS, "key", "asc")}
                                selectionMode={SelectionMode.none}
                            />
                        </div>
                    }
                </div>

                {
                    !alertJSON.hideAlert &&
                    <AlertRedux
                        {...this.props}
                    />
                }
                {!hideAttachAssetConfirm &&
                    <ConfirmAttachAssetRedux {...this.props} />
                }
            </React.Fragment>
        )
    }
}
