import {
    GET_TASKS_COUNT,
    GET_ALL_TASKS_URL,
    GET_APRROVE_TASK_URL,
    GET_LLG_CHILD_TASK_URL,
    CHANGE_JOB_DETAILS_URL,
    RE_GENERATE_PDF_URL,
    GET_USERS_BY_TASKTYPE,
    APP_TASK_UPLOAD_URL,
    APP_TASK_UPLOAD_WEB_URL,
    LOAD_BLANK_LOCATION_TASK_URL,
    ATTACH_LOCATION_TO_TASK_URL
} from "../Constants/TaskConstants";
import { ALERT_MESSAGE } from "../../../Constants/MessageConstants";
import axios from 'axios';
import { SEND_NOTIFICATION } from "../../Jobs/Constants/JobConstants";
import { GET_FORM_DATA_URL, GET_FORM_JSON_URL, SAVE_CHILD_LLG_TASK_URL } from "../../Assets/Constants/AssetConstants";

export class TaskAPI {

    _URL = "";

    constructor(URL) {
        this._URL = URL;
    }

    _getTasksCount(config) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_TASKS_COUNT)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), config)
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error _getTasksCount method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    _getChunkTasks(offset, limit, config) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_ALL_TASKS_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?offset=${offset}&limit=${limit}`), config
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error _getChunkTasks method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    getTaskDetails(offset, limit, config) {
        let promises = [this._getChunkTasks(offset, limit, config), this._getTasksCount(config)];
        return Promise.all(promises);

    }

    getParentAndChildLLGTasks(taskId) {
        let promises = [this.getObservationById(taskId), this.getLLGChildTasks(taskId)];
        return Promise.all(promises);
    }

    generateOrReopenTaskAPI(task_id, task_status, revisionComments, newInspector, isWebInspection) {
        return new Promise((resolve, reject) => {
            axios.put(this._URL
                .concat(`${GET_ALL_TASKS_URL}/${task_id}/${task_status}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?revisionComments=${revisionComments ? encodeURIComponent(revisionComments) : ""}`)
                .concat(`${newInspector ? "&newInspector=" + newInspector : ""}`)
                .concat(`${isWebInspection ? "&isWebInspection=true" : ""}`)
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error generateOrReopenTask method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    approveTaskAPI(task_id) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${GET_APRROVE_TASK_URL}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?id=${task_id}`)
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error approveTaskAPI method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else if (error.response.data.error) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    changeJobDetails(changedJobSitePayload) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${CHANGE_JOB_DETAILS_URL}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                , changedJobSitePayload
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error changeJobDetails method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else if (error.response.data.error) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    regeneratePDF(observationId, isLLG) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${RE_GENERATE_PDF_URL}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?id=${observationId}`)
                .concat(`&preview=${true}`)
                .concat(`&isLLG=${isLLG}`)
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error regeneratePDF method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else if (error.response.data.error) {
                        reject(error.response.data.error);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    sendNotification(payload, messageType) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL.concat(SEND_NOTIFICATION)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(messageType ? `?messageType=${messageType}` : ``)
                , payload).then(responseData => {
                    resolve(responseData);
                }).catch(error => {
                    console.error("Error sendNotification method", error);
                });
        });
    }

    getLLGChildTasks = (observationId) => {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(`${GET_LLG_CHILD_TASK_URL}/`)
                .concat(`${observationId}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getLLGChildTasks method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    getInspector(taskTypeId) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(`${GET_USERS_BY_TASKTYPE}`)
                .concat(`${taskTypeId}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
            ).then((response) => {
                resolve(response.data);
            }).catch(error => {
                console.error("Error getInspector method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    getObservationById(task_id) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(`${GET_ALL_TASKS_URL}/${task_id}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error getObservationById method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    saveExistingCheckList(observationId, checklistData, files) {
        const formData = new FormData();
        formData.append('observationId', observationId)
        formData.append('checklistData', JSON.stringify(checklistData))
        files.forEach((file) => formData.append('uploadFile', file))
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${APP_TASK_UPLOAD_WEB_URL}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), formData, config
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error saveExistingCheckList method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    appTaskUpload(observations) {
        const formData = new FormData();
        formData.append('data', JSON.stringify(observations))
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${APP_TASK_UPLOAD_URL}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``), formData, config
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error appTaskUpload method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    loadFormMetadata(formId) {
        return new Promise((resolve, reject) => {
            axios.get(this._URL
                .concat(GET_FORM_DATA_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?formId=${formId}`)
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error loadFormMetadata: ", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    saveFormMetadata(formId, formJson) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(GET_FORM_JSON_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                , { formId: formId, formJson: formJson }
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error saveFormMetadata: ", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

    updateTaskCheckList(task_id, task_status, isToUpdateChecklist) {
        return new Promise((resolve, reject) => {
            axios.put(this._URL
                .concat(`${GET_ALL_TASKS_URL}/${task_id}/${task_status}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?isToUpdateChecklist=${isToUpdateChecklist}`)
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error updateTaskCheckList method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    getAssetsForBlankTask(observationId) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${LOAD_BLANK_LOCATION_TASK_URL}/${observationId}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                console.error("Error getAssetsForBlankTask method", error);
                if (error.response && error.response.status === 401) {
                    reject(ALERT_MESSAGE.ERROR_401);
                }
                else {
                    reject(ALERT_MESSAGE.COMMON_ERROR);
                }
            });
        });
    }

    attachAssetsForBlankTask(observationId, locationId, isShiftAndAttach) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(`${ATTACH_LOCATION_TO_TASK_URL}/${observationId}/${locationId}`)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(isShiftAndAttach ? `?isShiftAndAttach=${isShiftAndAttach}` : ``)
            )
                .then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    console.error("Error updateTaskCheckList method", error);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                });
        });
    }

    createChildLLGObservation(parentLLGTaskId, taskTypeId, taskTypeName) {
        return new Promise((resolve, reject) => {
            axios.post(this._URL
                .concat(SAVE_CHILD_LLG_TASK_URL)
                .concat(origin === "http://localhost:3000" ? `;jsessionid=${window.loggedInUserDetails.jsessionid}` : ``)
                .concat(`?parentLLGTaskId=${parentLLGTaskId}`)
                .concat(`&taskTypeId=${taskTypeId}`)
                .concat(`&taskTypeName=${taskTypeName}`)
            )
                .then(response => {
                    resolve(response);
                }).catch(error => {
                    console.error("Error saveFormMetadata: ", error.response);
                    if (error.response && error.response.status === 401) {
                        reject(ALERT_MESSAGE.ERROR_401);
                    }
                    else {
                        reject(ALERT_MESSAGE.COMMON_ERROR);
                    }
                })
        })
    }

}