import { ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH, ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH } from "../../Assets/Constants/AssetConstants";

export const CHANGE_TASK_TAB = "CHANGE_TASK_TAB";
export const CHANGE_LLG_TASK_TAB = "CHANGE_LLG_TASK_TAB";
export const SHOW_TASK_DETAILS_TAB = "SHOW_TASK_DETAILS_TAB";
export const RESET_TASK_BREADCRUMB = "RESET_TASK_BREADCRUMB";


//DASHBOARD
export const SHOW_HIDE_DASHBOARD_COLUMNS = "SHOW_HIDE_DASHBOARD_COLUMNS";
export const CLEAN_TASK_DASHBOARD = 'CLEAN_TASK_DASHBOARD';
export const CLEAN_TASK_DASHBOARD_WITH_FILTER = 'CLEAN_TASK_DASHBOARD_WITH_FILTER';
export const LOAD_TASK_DASHBOARD = 'LOAD_TASK_DASHBOARD';
export const FIX_TASK_DASHBOARD = 'FIX_TASK_DASHBOARD';
export const SORT_TASK_DASHBOARD = 'SORT_TASK_DASHBOARD';
export const OPEN_CLOSE_TASK_ALERT_MODAL = 'OPEN_CLOSE_TASK_ALERT_MODAL';
export const TOGGLE_TASK_VIEW = 'TOGGLE_TASK_VIEW';
export const SWITCH_LLG_TASK_VIEW = 'SWITCH_LLG_TASK_VIEW';

//DASHBOARD FILTER
export const CHANGE_TASK_STATUS_FILTER_VALUE = 'CHANGE_TASK_STATUS_FILTER_VALUE';
export const CHANGE_TASK_CLIENT_FILTER_VALUE = 'CHANGE_TASK_CLIENT_FILTER_VALUE';
export const CHANGE_TASK_SITE_FILTER_VALUE = 'CHANGE_TASK_SITE_FILTER_VALUE';
export const CHANGE_TASK_JOB_NUMBER_FILTER_VALUE = 'CHANGE_TASK_JOB_NUMBER_FILTER_VALUE';
export const CHANGE_TASK_CERTIFICATE_NUMBER_FILTER_VALUE = 'CHANGE_TASK_CERTIFICATE_NUMBER_FILTER_VALUE';
export const CHANGE_TASK_ASSET_FILTER_VALUE = 'CHANGE_TASK_ASSET_FILTER_VALUE';
export const CHANGE_TASK_ASSET_TYPE_FILTER_VALUE = 'CHANGE_TASK_ASSET_TYPE_FILTER_VALUE';
export const CHANGE_TASK_QUOTATION_NUMBER_FILTER_VALUE = 'CHANGE_TASK_QUOTATION_NUMBER_FILTER_VALUE';

//PREVIEW
export const OPEN_CLOSE_TASK_CONFIRM_MODAL = 'OPEN_CLOSE_TASK_CONFIRM_MODAL';
export const LOAD_OTHER_TASK_INSPECTORS = 'LOAD_OTHER_TASK_INSPECTORS';
export const CHANGE_AND_REASSIGN_TASK_INSPECTOR = 'CHANGE_AND_REASSIGN_TASK_INSPECTOR';
export const GENERATE_TASK_PDF = 'GENERATE_TASK_PDF';
export const SHOW_TASK_PDF = 'SHOW_TASK_PDF';
export const CLEAN_TASK_PDF = 'CLEAN_TASK_PDF';
export const LOAD_UNLOAD_TASK_PREVIEW = 'LOAD_UNLOAD_TASK_PREVIEW';
export const REOPEN_OR_GENERATE_TASK = 'REOPEN_OR_GENERATE_TASK';
export const CHANGE_REVISION_COMMENTS = 'CHANGE_REVISION_COMMENTS';
export const SHOW_TASK_PREVIEW_CERTIFICATE_BROWSER = 'SHOW_TASK_PREVIEW_CERTIFICATE_BROWSER';
export const LOAD_CHILD_LLG_TASKS = 'LOAD_CHILD_LLG_TASKS';
export const LOAD_CHILD_LLG_TASK_LIST = 'LOAD_CHILD_LLG_TASK_LIST';
export const SELECT_CHILD_LLG_TASK = 'SELECT_CHILD_LLG_TASK';
export const CHANGE_CHILD_LLG_TASK_SEARCH_TEXT = 'CHANGE_CHILD_LLG_TASK_SEARCH_TEXT';

// JOB Details
export const SHOW_HIDE_TIME_CLOCK = 'SHOW_HIDE_TIME_CLOCK';
export const UPLOAD_JOB_FILES_FROM_TASK = 'UPLOAD_JOB_FILES_FROM_TASK';
export const ON_CHANGE_SELECTED_TASK_JOB_DETAILS_TAB = 'ON_CHANGE_SELECTED_TASK_JOB_DETAILS_TAB';
export const ON_SAVE_SELECTED_TASK_JOB_DETAILS_TAB = 'ON_SAVE_SELECTED_TASK_JOB_DETAILS_TAB';

// Inspect
export const FIND_OBSERVATION_BY_ID = 'FIND_OBSERVATION_BY_ID';
export const FIND_LLG_OBSERVATION_BY_ID = 'FIND_LLG_OBSERVATION_BY_ID';
export const FIND_OBSERVATIONS_BY_PARENT_ID = 'FIND_OBSERVATIONS_BY_PARENT_ID';
export const FIND_FORM_DETAILS_BY_ID = 'FIND_FORM_DETAILS_BY_ID';
export const POPULATE_FORM_DETAILS = 'POPULATE_FORM_DETAILS';
export const SAVE_CHECKLIST_DETAILS = 'SAVE_CHECKLIST_DETAILS';
export const LOAD_ITEM_LIST = 'LOAD_ITEM_LIST';
export const SUBMIT_OR_HOLD_TASK = 'SUBMIT_OR_HOLD_TASK';
export const OPEN_CLOSE_CREATE_CHILD_TASK_PANEL = 'OPEN_CLOSE_CREATE_CHILD_TASK_PANEL';
export const LOAD_TASKTYPES = 'LOAD_TASKTYPES';
export const SELECT_TASKTYPE = 'SELECT_TASKTYPE';
export const SAVE_CHILD_LLG_TASK = 'SAVE_CHILD_LLG_TASK';

// Attach Asset
export const LOAD_ASSETS_FOR_BLANK_LOCATION_TASK = 'LOAD_ASSETS_FOR_BLANK_LOCATION_TASK';
export const ATTACH_ASSET_TO_BLANK_LOCATION_TASK = 'ATTACH_ASSET_TO_BLANK_LOCATION_TASK';
export const CHANGE_ASSET_NAME_IN_ATTACH_ASSET = 'CHANGE_ASSET_NAME_IN_ATTACH_ASSET';
export const CHANGE_ATTACH_ASSET_SEARCH_FIELD_VALUE = 'CHANGE_ATTACH_ASSET_SEARCH_FIELD_VALUE';
export const OPEN_CLOSE_ATTACH_ASSSET_CONFIRM_MODAL = 'OPEN_CLOSE_ATTACH_ASSSET_CONFIRM_MODAL';

//URL
export const GET_ALL_TASKS_URL = "Observation";
export const GET_ALL_TASKS_BY_PARENT_ID_URL = "Observation/parent";
export const APP_TASK_UPLOAD_URL = "apptaskupload";
export const APP_TASK_UPLOAD_WEB_URL = "apptaskuploadWeb";
export const SAVE_CHECKLIST_URL = "Observation/checklist";
export const GET_ALL_REPORTS_URL = "Reports";
export const GET_ALL_ASSESSMENTS_URL = "Assessments";
export const GET_APRROVE_TASK_URL = "approvetaskweb";
export const GET_TASKS_COUNT = "Observation/count";
export const GET_TASK_PDF = "previewtaskcertificate";
export const GET_CLOSED_TASK_PDF = "filedownload";
export const GET_LLG_CHILD_TASK_URL = "Observation/getchildLLG";
export const GET_USERS_BY_TASKTYPE = "Users/tasktype/";
export const CHANGE_JOB_DETAILS_URL = "Observation/changejobdetails";
export const RE_GENERATE_PDF_URL = "addasynctask";
export const LOAD_BLANK_LOCATION_TASK_URL = "Location/Observations";
export const ATTACH_LOCATION_TO_TASK_URL = "Observation/Asset";

export const ATTACH_ASSET_COLUMNS = [
    {
        key: 'column1',
        isSorted: true,
        name: 'Name',
        fieldName: 'name',
        minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
        maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
        isResizable: true
    },
    {
        key: 'column2',
        isSorted: false,
        name: 'Asset Type',
        fieldName: 'assetTypeName',
        minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
        maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
        isResizable: true
    },
    {
        key: 'column3',
        name: 'Client',
        isSorted: false,
        fieldName: 'client',
        minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
        maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
        isResizable: true
    },
    {
        key: 'column4',
        name: 'Site',
        isSorted: false,
        fieldName: 'site',
        minWidth: ASSET_OBSERVATIONS_COLUMN_MIN_WIDTH,
        maxWidth: ASSET_OBSERVATIONS_COLUMN_MAX_WIDTH,
        isResizable: true
    }
];


export const TaskTabs = {
    0: "Job Details",
    1: "Asset Details",
    2: "Preview",
}


export const TASK_STATUS = {
    1024: "DELETED",
    1: "OPEN",
    2: "ONGOING",
    4: "HOLD",
    8: "COMPLETED",
    // 8: "APPROVED",
    16: "CLOSED",
    32: "AMENDED",
    64: "CANCELLED"
}

export const TASK_STATUS_TO_UPDATE = {
    2: "Reopen",
    4: "Hold",
    16: "Generate",
    8: "Complete",
    32: "Amend",
    64: "Cancel",
}

export const TASK_STATUS_UPDATED = {
    2: "Reopened",
    4: "Hold",
    16: "Generated",
    8: "Completed",
    32: "Amended",
    64: "Cancelled",
}

export const TASK_STATUS_DROPDOWN_FILTER_OPTIONS = [
    { key: '', text: 'All States' },
    { key: 1, text: 'OPEN' },
    { key: 2, text: 'ONGOING' },
    { key: 4, text: 'HOLD' },
    { key: 8, text: 'COMPLETED' },
    { key: 16, text: 'CLOSED' },
    { key: 32, text: 'AMENDED' },
    { key: 64, text: 'CANCELLED' },
];

export const TASK_ASSET_TYPE_DROPDOWN_FILTER_OPTIONS = [
    { key: '', text: 'All Categories' },
    { key: 'MACHINERY', text: 'MACHINERY' },
    { key: 'BTH+PPE', text: 'BTH+PPE' },
    { key: 'TRAINEE', text: 'TRAINEE' },
];

export const TASK_STATUS_OPEN = 1;
export const TASK_STATUS_ONGOING = 2;
export const TASK_STATUS_REVERTED = 2;
export const TASK_STATUS_ONHOLD = 4;
export const TASK_STATUS_CONFIRMED = 8;
export const TASK_STATUS_CLOSED = 16;
export const TASK_STATUS_AMENDED = 32;
export const TASK_STATUS_CANCELLED = 64;
export const TASK_STATUS_DELETED = 1024;

export const ASSET_SAVE_DESCRIPTION_DISABLED = [
    TASK_STATUS_CONFIRMED, TASK_STATUS_CLOSED,
    TASK_STATUS_CANCELLED, TASK_STATUS_DELETED
];
