import { DefaultButton, DocumentCard, Label, PrimaryButton, TextField } from 'office-ui-fabric-react'
import * as React from 'react'
import { IconConstants } from '../../Constants/StylesConstants';
import CommonAPIServices from '../../CommonAPIService/CommonAPIServices';
import {AssetAPI} from '../Assets/Services/AssetAPI';
import { CREDENTIALS } from '../../Constants/CommonConstants';
import { LoginAPI } from './LoginAPI';
import Alert from '../Alert/Alert';
import LoadingOverlay from "react-loading-overlay";
import { FadeLoader } from "react-spinners";
import qwikspecLogo from './../../images/logo-light-color.svg';
import CustomPanel from './CustomPanel';

export default class QwikspecLogin extends React.Component<any, any> {
    _apiService;
    _commonAPIService;
    _assetAPI;

    constructor(props) {
        super(props);
        this.state = {
            user: "", 
            password: "",
            loading: false,
            errorMessage: "",
            newPassword: "",
            confirmNewPassword: "",
            otp: "",
            newPasswordErrorMessage: "",
            confirmPasswordErrorMessage: "",
            otpErrorMessage: "",
            resetEmail: "",
            isLogin: true,
            isOTPSent: false,
            alertJSON: {
                hideAlert: true,
                alertHeader: "",
                alertSubText: ""
            },
            isShowExistingPassword: false,
            isShowNewPassword: false,
            isShowConfirmPassword: false,
            showPopup: false, // State for managing popup visibility
        }
        this._apiService = new LoginAPI(window.API_URL);
        this._commonAPIService = new CommonAPIServices(window.API_URL);
        this._assetAPI = new AssetAPI(window.API_URL, window.loggedInUserDetails);
    }

    doLogin = () => {
        this.setState({
            loading: true,
            showPanel:false,
        });
        let url = window.location.href;
        let payload={};
// Parse the URL to get the search parameters
       let searchParams = new URLSearchParams(url.split('?')[1]);
        if(searchParams.has("asn")){
            // Get the value of the "asn" parameter
             let asn = searchParams.get("asn");
             payload = {
                data: window.origin === "http://localhost:3000" ?
                    CREDENTIALS[30]
                    :
                    {
                        email: this.state.user.indexOf("@") > -1 ? this.state.user : this.state.user.concat("@qwikspec.com"),
                        password: this.state.password,
                        isAsn:true,
                        asn:asn
                    }
            }
        }else{
            payload = {
                data: window.origin === "http://localhost:3000" ?
                    CREDENTIALS[30]
                    :
                    {
                        email: this.state.user.indexOf("@") > -1 ? this.state.user : this.state.user.concat("@qwikspec.com"),
                        password: this.state.password,
                        isAsn:false
                    }
            }
        }
        
        this._apiService.doLogin(payload).then((response) => {
            sessionStorage.setItem("jsessionid", response[0].jsessionid);
            this._commonAPIService.getCustomerLogo().then((companyLogoDetailsResp) => {
                window.isAuthenticated = true;
                window.loggedInUserDetails = response[0];
                sessionStorage.setItem("companyLogo", companyLogoDetailsResp.companyLogo);
                sessionStorage.setItem("fileType", companyLogoDetailsResp.fileType);
                window.loggedInUserDetails.customerLogoType = companyLogoDetailsResp.fileType;
                window.loggedInUserDetails.customerLogo = companyLogoDetailsResp.companyLogo;
                this.setState({
                    loading: false
                }, () => {       
                    if (searchParams.has("asn")) {
                        console.log("URL has asn");
                        console.log(window.loggedInUserDetails.jsessionid);
                     let asn = searchParams.get("asn");
                    this._assetAPI.loadAssetObservationsForAsnAPI(asn, 0, 50, '',window.loggedInUserDetails.jsessionid)
                        .then(observationsResponse => {
                            this.setState({
                                showPopup: true, // Show the popup
                                observations: observationsResponse.data // Store the observations data
                            });
                        })
                        .catch(error => {
                            console.error("Failed to load asset observations:", error);
                            this.setState({
                                showPopup: false,
                                errorMessage: error.error
                            });
                        });
                    } else {
                        console.log("URL does not have asn");
                        this.props.history.push('/');
                    }      
                })

            });
        }).catch((error) => {
            this.setState({
                loading: false,
                errorMessage: error.error
            });
        })
    }
    handleLogout = () => {
        this.setState({ showPopup: false });
        //this._apiService.doLogout();
        setTimeout(() => {
            this._apiService.doLogout()
                .then(() => {
                    window.isAuthenticated = false;
                    // window.loggedInUserDetails = null;
                    this.setState({
                      //  HideLogoutConfirmationDialog: true
                    }, () => {
                        this.props.history.push("/")
                    });

                }).catch((error) => {
                    console.log("Error whille Log out", error);
                });
        }, 1500);
      };
    
      
    generateOTP = () => {
        this.setState({
            loading: true,
        });

        let payload = {
            data:
            {
                email: this.state.resetEmail,
            }
        }
        this._apiService.generateOTP(payload).then(() => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON = {
                alertHeader: "Success!!",
                alertSubText: "OTP has been sent to : " + this.state.resetEmail,
                hideAlert: false
            };
            this.setState({
                loading: false,
                alertJSON,
                isOTPSent: true,
            }, () => {
                setTimeout(() => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON = {
                        alertHeader: "",
                        alertSubText: "",
                        hideAlert: true
                    };
                    this.setState({
                        alertJSON
                    });
                }, 2000);
            });
        }).catch((error) => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON = {
                alertHeader: "Oops!!",
                alertSubText: error,
                hideAlert: false
            };
            this.setState({
                loading: false,
                alertJSON
            }, () => {
                setTimeout(() => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON = {
                        alertHeader: "",
                        alertSubText: "",
                        hideAlert: true
                    };
                    this.setState({
                        alertJSON
                    });
                }, 2000);
            });
        })
    }

    resetPassword = () => {
        this.setState({
            loading: true,
            isShowConfirmPassword: false,
            isShowExistingPassword: false,
            isShowNewPassword: false
        });

        let payload = {
            data:
            {
                email: this.state.resetEmail,
                newPassword: this.state.newPassword,
                otp: this.state.otp
            }
        }
        this._apiService.resetPassword(payload).then((response) => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON = {
                alertHeader: "Success!!",
                alertSubText: "Your password has been reset successfully ",
                hideAlert: false
            };
            this.setState({
                loading: false,
                alertJSON,
                isOTPSent: false,
                otp: "",
                newPassword: "",
                confirmNewPassword: "",
                resetEmail: "",
                user: ""
            }, () => {
                setTimeout(() => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON = {
                        alertHeader: "",
                        alertSubText: "",
                        hideAlert: true
                    };
                    this.setState({
                        alertJSON,
                        isLogin: true
                    });
                }, 2000);
            });
        }).catch((error) => {
            let alertJSON = { ...this.state.alertJSON };
            alertJSON = {
                alertHeader: "Oops!!",
                alertSubText: error,
                hideAlert: false,
                isOTPSent: false
            };
            this.setState({
                loading: false,
                alertJSON
            }, () => {
                setTimeout(() => {
                    let alertJSON = { ...this.state.alertJSON };
                    alertJSON = {
                        alertHeader: "",
                        alertSubText: "",
                        hideAlert: true
                    };
                    this.setState({
                        alertJSON
                    });
                }, 2000);
            });
        })
    }

    render() {

        let {
            loading,
            errorMessage,
            isLogin,
            isOTPSent,
            newPassword,
            newPasswordErrorMessage,
            confirmNewPassword,
            confirmPasswordErrorMessage,
            otp,
            otpErrorMessage,
            alertJSON,
            resetEmail,
            isShowExistingPassword,
            isShowNewPassword,
            isShowConfirmPassword
        } = this.state;
        let isEnabledConfirmButton =
            newPassword
            && confirmNewPassword
            && otp
            && newPassword === confirmNewPassword
            && newPassword.length >= 6
            && newPassword.length <= 18;

        return (
            <>

                <LoadingOverlay
                    active={loading}
                    spinner={<FadeLoader />}
                    text="Please wait..."

                >
                    <div className="custom-login-component">

                        <div className="qwikspec-login-container">
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <a className="top-logo" href="https://qwikspec.com/" target="blank">
                                            <img src={qwikspecLogo} width="200" height="90" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Label className='login-label'></Label>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <DocumentCard styles={{
                                            root: {
                                                maxWidth: "fit-content"
                                            }
                                        }}>
                                            {isLogin ?
                                                <div className='login-container-item'>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                            <TextField
                                                                required
                                                                autoFocus
                                                                placeholder="Email or Namespace"
                                                                iconProps={IconConstants.publicEmailIcon}
                                                                value={this.state.user}
                                                                onKeyPress={(event) => {
                                                                    if (event.charCode === 13) {
                                                                        this.doLogin()
                                                                    }
                                                                }}
                                                                onChange={(event, newVal) => {
                                                                    this.setState({
                                                                        user: newVal,
                                                                        errorMessage: ""
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                            <TextField
                                                                placeholder='Password'
                                                                required
                                                                type={isShowExistingPassword ? `text` : `password`}
                                                                value={this.state.password}
                                                                onKeyPress={(event) => {
                                                                    if (event.charCode === 13) {
                                                                        this.doLogin()
                                                                    }
                                                                }}
                                                                onChange={(event, newVal) => {
                                                                    this.setState({
                                                                        password: newVal,
                                                                        errorMessage: ""
                                                                    });
                                                                }}
                                                                iconProps={{
                                                                    iconName: this.state.isShowExistingPassword ?
                                                                        IconConstants.hidePasswordIcon.iconName : IconConstants.showPasswordIcon.iconName,
                                                                    style: { pointerEvents: "auto", cursor: "pointer" },
                                                                    onClick: () => {
                                                                        this.setState({
                                                                            isShowExistingPassword: !this.state.isShowExistingPassword
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                            {errorMessage &&
                                                                <div className="mandatory-span custom-login-error">{errorMessage}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                                            <PrimaryButton
                                                                text="Login"
                                                                menuIconProps={IconConstants.signinIcon}
                                                                onClick={() => {
                                                                    this.doLogin()
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                                            <DefaultButton
                                                                text="Reset Password"
                                                                className="submit cancel-button"
                                                                menuIconProps={IconConstants.resetIcon}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        isLogin: false,
                                                                        isOTPSent: false,
                                                                        otp: "",
                                                                        newPassword: "",
                                                                        confirmNewPassword: "",
                                                                        resetEmail: "",
                                                                        isShowConfirmPassword: false,
                                                                        isShowExistingPassword: false,
                                                                        isShowNewPassword: false,
                                                                        password: "",
                                                                        user: ""
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                // OTP Sent
                                                :
                                                !isOTPSent ?
                                                    <>
                                                        <div className='login-container-item'>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    <TextField
                                                                        placeholder="Email"
                                                                        iconProps={IconConstants.publicEmailIcon}
                                                                        value={resetEmail}
                                                                        onChange={(event, newVal) => {
                                                                            this.setState({
                                                                                resetEmail: newVal,
                                                                                errorMessage: ""
                                                                            });
                                                                        }}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg5">
                                                                    <PrimaryButton
                                                                        text="Request OTP"
                                                                        menuIconProps={IconConstants.numberIcon}
                                                                        disabled={!this.state.resetEmail}
                                                                        onClick={() => this.generateOTP()}
                                                                    />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg7">
                                                                    <DefaultButton
                                                                        className="cancel-button"
                                                                        text="Back to Login Screen"
                                                                        iconProps={IconConstants.previousIcon}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isLogin: true,
                                                                                isShowConfirmPassword: false,
                                                                                isShowExistingPassword: false,
                                                                                isShowNewPassword: false
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className='login-container-item'>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    <TextField
                                                                        value={newPassword}
                                                                        type={isShowNewPassword ? `text` : `password`}
                                                                        placeholder="New Password"
                                                                        iconProps={{
                                                                            iconName: this.state.isShowNewPassword ?
                                                                                IconConstants.hidePasswordIcon.iconName : IconConstants.showPasswordIcon.iconName,
                                                                            style: { pointerEvents: "auto", cursor: "pointer" },
                                                                            onClick: () => {
                                                                                this.setState({
                                                                                    isShowNewPassword: !this.state.isShowNewPassword
                                                                                })
                                                                            }
                                                                        }}
                                                                        onChange={(event, newVal) => {
                                                                            this.setState({
                                                                                newPassword: newVal,
                                                                                newPasswordErrorMessage:
                                                                                    newVal ?
                                                                                        newVal.trim().length < 6 || newVal.trim().length > 18 ?
                                                                                            "Password must be between 6 to 18 characters"
                                                                                            :
                                                                                            confirmNewPassword === newVal ?
                                                                                                "" :
                                                                                                "Must match with confirm password" :
                                                                                        "New password is mandatory",
                                                                                confirmPasswordErrorMessage:
                                                                                    newVal ?
                                                                                        newVal === confirmNewPassword ?
                                                                                            "" : "Must match with new password" : "Confirm password is mandatory"
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    {newPasswordErrorMessage &&
                                                                        <div className="mandatory-span custom-login-error">{newPasswordErrorMessage}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    <TextField
                                                                        value={confirmNewPassword}
                                                                        type={isShowConfirmPassword ? `text` : `password`}
                                                                        placeholder="Confirm Password"
                                                                        iconProps={{
                                                                            iconName: this.state.isShowConfirmPassword ?
                                                                                IconConstants.hidePasswordIcon.iconName : IconConstants.showPasswordIcon.iconName,
                                                                            style: { pointerEvents: "auto", cursor: "pointer" },
                                                                            onClick: () => {
                                                                                this.setState({
                                                                                    isShowConfirmPassword: !this.state.isShowConfirmPassword
                                                                                })
                                                                            }
                                                                        }}
                                                                        onChange={(event, newVal) => {
                                                                            this.setState({
                                                                                confirmNewPassword: newVal,
                                                                                newPasswordErrorMessage:
                                                                                    newPassword ?
                                                                                        newPassword.trim().length < 6 || newPassword.trim().length > 18 ?
                                                                                            "Password must be between 6 to 18 characters"
                                                                                            :
                                                                                            newPassword === newVal ?
                                                                                                "" :
                                                                                                "Must match with confirm password" :
                                                                                        "New password is mandatory",
                                                                                confirmPasswordErrorMessage:
                                                                                    newVal ?
                                                                                        newVal === newPassword ?
                                                                                            "" : "Must match with new password" : "Confirm password is mandatory"
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    {confirmPasswordErrorMessage &&
                                                                        <div className="mandatory-span custom-login-error">{confirmPasswordErrorMessage}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    <TextField
                                                                        value={otp}
                                                                        type="password"
                                                                        placeholder="OTP"
                                                                        iconProps={IconConstants.passwordIcon}
                                                                        onChange={(event, newVal) => {
                                                                            this.setState({
                                                                                otp: newVal,
                                                                                otpErrorMessage: newVal ? "" : "OTP is mandatory"
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg12">
                                                                    {otpErrorMessage &&
                                                                        <div className="mandatory-span custom-login-error">{otpErrorMessage}</div>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <br />
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg5">
                                                                    <PrimaryButton
                                                                        text="Confirm"
                                                                        menuIconProps={IconConstants.confirmIcon}
                                                                        disabled={!isEnabledConfirmButton}
                                                                        onClick={() => this.resetPassword()}
                                                                    />
                                                                </div>
                                                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg7">
                                                                    <DefaultButton
                                                                        className="cancel-button"
                                                                        text="Back to Login Screen"
                                                                        iconProps={IconConstants.previousIcon}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                isLogin: true,
                                                                                isShowConfirmPassword: false,
                                                                                isShowExistingPassword: false,
                                                                                isShowNewPassword: false
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </DocumentCard>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </LoadingOverlay >
                {!alertJSON.hideAlert &&
                    <Alert
                        alertJSON={alertJSON}
                    />
                }
                 <CustomPanel
                    showPopup={this.state.showPopup}
                    onClose={this.handleLogout}
                    observations={this.state.observations}
        />
            </>
        )
    }
}
